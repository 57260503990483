<template>
    <div class="relative sales_statistics_panel">
        <!-- <dataTitle>
            <dateSearch @search="onSearchDetail"></dateSearch>
        </dataTitle> -->
        <DatePicker v-model="daterange" :transfer="true" @on-change="handleDateChange" split-panels confirm :editable="false" type="daterange" style="width: 200px" placeholder="自定义日期"></DatePicker>
        <Button type="primary" @click="onSearch" style="margin-left: 20px; margin-top: 0px">搜索</Button>
        <Button type="info" @click="reset" style="margin-left: 20px; margin-top: 0px">重置</Button>
        <dataTitle class="mt_20" slot="title" title="销售统计"></dataTitle>
        <div class="mt_10 relative flex flex_wrap align_center card_panel">
            <itemCard title="累计销售额" :decimalPlaces="2" :val="totalSalePrice"></itemCard>
            <itemCard title="今日销售" :decimalPlaces="2" :val="todaySalePrice"></itemCard>
            <!-- <itemCard title="分销商" :decimalPlaces="2" :val="todaySalePrice"></itemCard> -->
            <div style="position: relative; min-width: 400px; max-width: 400px; height: 150px; border: 2px #333333 solid; border-radius: 8px; display: flex; align-items: center; justify-content: center">
                <div style="position: absolute; left: 10px; top: 10px">分销商(总数)</div>
                <div style="font-size: 40px; font-weight: bold">{{ fxNum }}</div>
                <!-- <div style="position: absolute; top: 93px; right: 7px">
                    <div style="display: flex">
                        <div>省代理:0%</div>
                        <div style="margin-left: 12px">经销商:0%</div>
                    </div>
                    <div style="display: flex">
                        <div>个人:0%</div>
                        <div style="margin-left: 12px">小B端:0%</div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="mt_20 relative width_100_percent flex flex_wrap align_start space_between detail_panel">
            <div class="relative detail_item" style="width: 30%">
                <dataTitle slot="title" title="销售明细(近年)"></dataTitle>
                <div class="relative bar_panel">
                    <!-- <bar className="sales_statistics_detail_bar" yName="单位：数量" :chartData="salesDetail" :height="500"></bar> -->
                    <div class="wrap-item" style="height: 482px">
                        <div id="main2" ref="container" style="width: 550px; height: 350px; position: absolute; top: 50px"></div>
                    </div>
                </div>
            </div>
            <div class="relative detail_item" style="width: 30%">
                <dataTitle slot="title" title="单品销量Top10"></dataTitle>
                <div class="relative width_100_percent table_panel">
                    <Table stripe class="width_100_percent mt_10" :columns="salesRankColumn" :data="lcountArr1">
                        <template slot-scope="{ index }" slot="rankNo">
                            {{ index + 1 }}
                        </template>
                        <template slot-scope="{ row }" slot="goods">
                            <div class="flex align_center" style="padding: 10px 0">
                                <!-- <previewImage :thumWidth="50" :thumHeight="50" :src="row.icon"></previewImage> -->
                                <div class="ml_10">{{ row.name }}</div>
                            </div>
                        </template>
                    </Table>
                </div>
            </div>
            <div class="relative detail_item" style="width: 30%">
                <dataTitle slot="title" title="销售比例(近年)"></dataTitle>
                <div class="relative bar_panel">
                    <div class="wrap-item" style="margin-top: 60px; margin-left: 95px; height: 482px">
                        <div style="position: absolute; position: absolute; top: 165px; left: 208px; font-weight: bold">销售单量:{{ total }}</div>
                        <div id="main" ref="container" style="width: 500px; height: 300px; left: -97px; top: 27px; z-index: 66666"></div>
                    </div>
                </div>
            </div>
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import { reqSalesStat } from '@/lib/request/auth2';
import dataTitle from './comp/dataTitle';
import dateSearch from './comp/dateSearch';
import bar from './comp/echarts/bar';
import itemCard from './comp/itemCard';
import previewImage from '@/components/previewImage';
import { getFastapps, saveFastapps, getOrder, getAgentStatic, getUser, reqReservationList, agentStatic } from '@/lib/request/auth';

const moDef = {
    totalSalePrice: 'totalSalePrice', //累计销售额
    todaySalePrice: 'todaySalePrice', //今日销售
    saleDetail: 'saleDetail', //销售明细
    goodSaleDetail: 'goodSaleDetail', //单品销售TOP10
};
export default {
    name: 'salesStatistics',
    components: {
        dataTitle,
        dateSearch,
        bar,
        itemCard,
        previewImage,
    },
    data() {
        return {
            startdate: '',
            enddate: '',
            daterange: '',
            xsArr: [],
            marr: [],
            montDataArr: [],
            fxNum: 0,
            total: 0,
            lcountArr: [],
            detail: {},
            totalSalePrice: 0,
            todaySalePrice: 0,
            salesDetail: [],
            salesRankList: [],
            salesRankColumn: [
                { slot: 'rankNo', title: '排名', width: 70 },
                { slot: 'goods', title: '商品信息' },
                { key: 'count', title: '累计销量', width: 120 },
            ],
            goodsTableShow: false,
            lcountArr1: [],
        };
    },
    mounted() {
        this.getDetail();
        const currentYear = new Date().getFullYear();
        const firstDayOfYear = new Date(currentYear, 0, 1);
        const date = new Date(firstDayOfYear);
        this.getData('bt', null, this.formatDate(date));
        this.getData('zx', 'month', this.formatDate(date));
        this.getAgentData('bt', null, this.formatDate(date));

        setTimeout(() => {
            this.$nextTick(() => {
                // this.initTreeData();
                this.draw();
                this.drawzx2();
            });
        }, 2000);
    },
    watch: {
        //
    },
    methods: {
        handleDateChange(date) {
            this.startdate = date[0];
            this.enddate = date[1];
            // this.getData('bt', null, this.startdate, this.enddate);
            setTimeout(() => {
                this.$nextTick(() => {
                    this.draw();
                });
            }, 1000);
        },
        reset() {
            const currentYear = new Date().getFullYear();
            const firstDayOfYear = new Date(currentYear, 0, 1);
            const date = new Date(firstDayOfYear);
            this.getData('bt', null, this.formatDate(date));
            this.getData('zx', 'month', this.formatDate(date));
            this.getAgentData('bt', null, this.formatDate(date));
            this.daterange = undefined
            setTimeout(() => {
                this.$nextTick(() => {
                    // this.initTreeData();
                    this.draw();
                    this.drawzx2();
                });
            }, 2000);
        },
        onSearch(){
            this.getData('bt', null, this.startdate,this.enddate);
            this.getData('zx', 'month', this.startdate,this.enddate);
            this.getAgentData('bt', null, tthis.startdate,this.enddate);
            setTimeout(() => {
                this.$nextTick(() => {
                    // this.initTreeData();
                    this.draw();
                    this.drawzx2();
                });
            }, 2000);
        },
        formatDate(date) {
            const year = date.getFullYear();
            // getMonth() 返回的月份是从 0 开始的，因此需要加 1
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        drawzx2() {
            var chartDom = document.getElementById('main2');
            var myChart = echarts.init(chartDom);
            var option;
            option = {
                title: {
                    text: '',
                },
                tooltip: {
                    trigger: 'axis',
                },
                legend: {
                    data: ['销售额'],
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                // toolbox: {
                //     feature: {
                //         saveAsImage: {},
                //     },
                // },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.marr,
                },
                yAxis: {
                    type: 'value',
                },
                series: [
                    {
                        name: '销售额',
                        type: 'line',
                        stack: 'Total',
                        data: this.xsArr,
                    },
                ],
            };

            option && myChart.setOption(option);
        },
        getData(name, type, startTime, endTime) {
            getOrder({ start: startTime, end: endTime, status: '1,2,3,4', type: type }).then(res => {
                if (name == 'bt') {
                    var lcountArr = [];
                    this.total = res.data[startTime].count;
                    this.totalSalePrice = res.data[startTime].amount;
                    console.log(res.data[startTime].data_good.length, 'res.data[startTime].data_good.length');
                    if (res.data[startTime].data_good.length != 0) {
                        for (var l in res.data[startTime].data_good) {
                            console.log(res.data[startTime].data_good[l].count, 'l.con');
                            var obj = {};
                            obj.name = res.data[startTime].data_good[l].name;
                            obj.count = res.data[startTime].data_good[l].count;
                            lcountArr.push(obj);
                            //遍历对象，k即为key，obj[k]为当前k对应的值
                        }
                        this.lcountArr1 = lcountArr;
                        var newsortArr = this.lcountArr1.sort((a, b) => b.count - a.count);
                        this.lcountArr1 = newsortArr.filter((item, index) => index < 10);
                        console.log(this.lcountArr1, 'lcountArr1');
                        this.lcountArr = [
                            { value: lcountArr[0].count, name: lcountArr[0].name.slice(0, 6) },
                            { value: lcountArr[1].count, name: lcountArr[1].name.slice(0, 6) },
                            { value: lcountArr[2].count, name: lcountArr[2].name.slice(0, 6) },
                            { value: lcountArr[3].count, name: lcountArr[3].name.slice(0, 6) },
                            { value: lcountArr[4].count, name: lcountArr[4].name.slice(0, 6) },
                        ];
                    } else {
                        this.lcountArr = [];
                    }
                    setTimeout(() => {
                        this.$nextTick(() => {
                            // this.initTreeData();
                            this.draw();
                        });
                    }, 1000);
                    console.log(this.lcountArr, 'this.lcountArr6666666666666');
                } else if (name == 'zx') {
                    var arr = [];
                    var marr = [];
                    for (var k in res.data) {
                        //遍历对象，k即为key，obj[k]为当前k对应的值
                        console.log(res.data[k]);
                        arr.push(res.data[k]);
                        marr.push(k);
                    }
                    this.marr = marr;
                    this.montDataArr = arr.map(item => {
                        return item.count;
                    });
                    this.xsArr = arr.map(item => {
                        return item.amount;
                    });
                    setTimeout(() => {
                        this.$nextTick(() => {
                            // this.drawzx();
                            this.drawzx2();
                        });
                    }, 2000);
                    console.log(this.montDataArr, 'this.montDataArr');
                }
            });
        },
        getAgentData(name, type, startTime, endTime) {
            agentStatic({ start: startTime, end: endTime, status: '1,2,3,4', type: type }).then(res => {
                console.log(res, 'res');
                this.fxNum = res.data[0].count;
            });
        },
        draw() {
            var chartDom = document.getElementById('main');
            var myChart = echarts.init(chartDom);
            var option;
            var lcountArr = this.lcountArr;
            console.log(lcountArr, 'lcountArr**************');
            option = {
                tooltip: {
                    trigger: 'item',
                },
                legend: {
                    left: '75%',
                    orient: 'vertical', // 设置图例竖向展示
                    x: 'right', // 设置图例位置在右侧
                    y: 'center', // 设置图例垂直居中
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        padAngle: 5,
                        itemStyle: {
                            borderRadius: 10,
                        },
                        label: {
                            show: false,
                            position: 'center',
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 40,
                                fontWeight: 'bold',
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: this.lcountArr,
                    },
                ],
            };

            option && myChart.setOption(option);
        },
        //获取详情
        getDetail() {
            this.showAjaxLoading();
            let reqData = {
                mo: moDef.todaySalePrice,
            };
            reqSalesStat(reqData)
                .then(res => {
                    this.todaySalePrice = +(res?.data?.row || 0) || 0;
                    console.log('今日销售额 request', reqData, 'res', res);
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //查询销售明细、单品销量
        onSearchDetail(arrDate) {
            console.log('onSearchDetail', arrDate);
            this.showAjaxLoading();
            let timeDate = '';
            if (!!arrDate[0] && !!arrDate[1]) {
                timeDate = JSON.stringify([arrDate[0], arrDate[1]]);
            }

            let reqData = {
                mo: moDef.totalSalePrice,
                timeDate,
            };
            reqSalesStat(reqData)
                .then(res => {
                    this.totalSalePrice = +(res?.data?.row || 0) || 0;
                    console.log('累计销售额 request', reqData, 'res', res);
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });

            let dataSalesStat = {
                mo: moDef.saleDetail,
                timeDate,
            };
            reqSalesStat(dataSalesStat)
                .then(res => {
                    let salesDetail = res?.data?.list || [];
                    if (salesDetail.length > 50) {
                        salesDetail = salesDetail.slice(0, 50);
                    }

                    salesDetail.forEach(info => {
                        info.name = info.ctime || '';
                        info.value = info.nums || 0;
                    });
                    this.salesDetail = salesDetail;
                    console.log('销售明细', dataSalesStat, 'res', res);
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });

            this.goodsTableShow = false;
            let dataGooodSale = {
                mo: moDef.goodSaleDetail,
                timeDate,
            };
            reqSalesStat(dataGooodSale)
                .then(res => {
                    this.salesRankList = res?.data?.goodList || [];
                    console.log('销售明细', dataGooodSale, 'res', res);
                })
                .finally(() => {
                    this.goodsTableShow = true;
                });
        },
    },
};
</script>

<style lang="less" scoped>
.sales_statistics_panel {
    .card_panel {
        gap: 15px;
        .card {
            flex: 1;
        }
    }

    .money_panel {
        width: 48%;
        min-width: 500px;
    }
    .detail_panel {
        gap: 20px;
        .detail_item {
            width: 48%;
            min-width: 500px;
        }
    }
}
</style>
